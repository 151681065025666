.main-navbar {
  .navbar-brand {
    margin: 0 !important;
    padding: 0 !important;

    .navbar-brand-image {
      padding: 5rem 0;
    }
  }
}
